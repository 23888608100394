// no double tap to zoom
* {
  touch-action: manipulation;
}

html {
  position: relative;
  min-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: roboto_monoregular;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #f8f8ff;
}

h2 {
  font-family: roboto_monobold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'roboto_monoregular';
  src: url('/fonts/robotomono-regular-webfont.woff2') format('woff2'),
    url('/fonts/robotomono-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_monobold';
  src: url('/fonts/robotomono-bold-webfont.woff2') format('woff2'),
    url('/fonts/robotomono-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: bold;
}
