
// global stuff
a {
  text-decoration: none;
  color: black;
}

h1,
h2 {
  font-weight: normal;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1rem;
}
